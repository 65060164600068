import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import { Helmet } from "react-helmet";

const TermsOfUsePage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - Terms of Use</title>
        </Helmet>
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9">
                    Terms of Use
                  </h1>
                  <p className="gr-text-8 mb-13">
                    Thanks for considering Akkadian Health!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                      These terms of service ("Terms") cover your use and access to our Services and website ("Services"). Akkadian Pty Ltd (trading as Akkadian Health) is not a provider of health services. Akkadian Health provides the technical infrastructure to allow third party health professionals to provide health services to you.
                    </p>
                    <p className="gr-text-9">
                      Our separate Privacy Policy explains how we collect and use your information while our Acceptable Use Policy (below) outlines your responsibilities when using our Services. By using our Services, you're agreeing to be bound by these Terms, and to review our Privacy and Acceptable Use policies.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Your Content &amp; Your Permissions</h3>
                    <p className="gr-text-9">
                      When you use our Services, you may provide us with various forms of content such as files, letters, email messages, journal entries and so on ("Your Content"). Your Content is yours. These Terms don't give us any rights to Your Content except for the limited rights that enable us to offer the Services.
                    </p>
                    <p className="gr-text-9">
                      We need your permission to do things like share Your Content. Our Services may also provide features (such a searching, editing, on so on) which may require our systems to access, store and scan Your Content. You give us permission to do those things, and this permission extends to trusted third parties we work with.
                    </p>
                    <p className="gr-text-9">
                      You give us permission to use information about your use of the Services in aggregated, dis-identified datasets to allow us to analyse the performance of the Services or to develop new Services. Furthermore, you also give us permission to contact you at any time in relation to the Services we offer.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Sharing Your Content</h3>
                    <p className="gr-text-9">
                      We never share Your Content with any entities outside of our organisation without your written permission. Your Content will only be made available to your treating Health Professional or an allocated supervising Health Professional. In addition, Your Content may be shared only with your referring Health Professional (GP or nurse practitioner) for the purposes of conducting our Services and as required under our obligations to the Medicare Benefits Scheme managed by the Australian Government Department of Health and administered by Services Australia..
                    </p>
                    <p className="gr-text-9">					
					You must grant us with written consent for the release of your medical records to a third party recipient (e.g., a new GP) by completing a Medical Records Release Form.
					</p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Data Processing</h3>
                    <p className="gr-text-9">
                      Akkadian will use, at a minimum, industry standard technical and security measures to transfer, store, and process Customer Data. These measures are designed to protect the integrity of Customer Data and guard against the unauthorised or unlawful access to, use, and processing of Customer Data. "Customer Data" means Stored Data and Account Data. "Stored Data" means the files, structured data and other content submitted to the Services by Customer or End Users. "Account Data" means the personal, billing and contact information submitted to the Services by Customer or End Users.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Your Responsibilities</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Conduct &amp; Content</strong> You are responsible for your conduct and Your Content and you must comply with our Acceptable Use Policy (below). Content in the Services may be protected by others' intellectual property rights. Please don't copy, upload, download or share content unless you have the right to do so.
                    </p>
                    <p className="gr-text-9">
                      We may review your conduct and content for compliance with these Terms and our Acceptable Use Policy.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Passwords</strong> Where applicable, please safeguard your password to the Services, make sure that others don't have access to it, and keep your account information current.
                    </p>
                  </div>
				  
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Our Intellectual Rights &amp; Copyright</h3>
                    <p className="gr-text-9">
                      The Services are protected by copyright, trademark, and other Australian and foreign laws. These Terms don't grant you any right, title or interest in the Services, others' content in the Services, trademarks, logos or other brand features. We welcome feedback, but note that we may use comments or suggestions without any obligation to you.
                    </p>
                    <p className="gr-text-9">
                      We respect the intellectual property of others and ask that you do too. We respond to notices of alleged copyright infringement if they comply with the law. We reserve the right to delete or disable content alleged to be infringing and terminate accounts of repeat infringers.
                    </p>
                  </div>

                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Fees &amp; Payment</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Changes</strong> We may change the Fees in effect for our services in line with Medicare MBS Item schedule fees (reviewed annually) and our own discretionary review of expenses (reviewed from time to time) but will give you notice of these fee changes if you are a returning client. Our latest Fee Schedule is always available on our website or upon request.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Payment of Fees</strong> Customer will pay, and authorise Akkadian to charge using Customer's selected payment method, for all applicable fees. Fees for health services received are non-refundable except as required by law. Customer is responsible for providing complete and accurate billing and contact information to Akkadian. Akkadian may cancel, suspend or terminate the Services if billing and contact information is incomplete, inaccurate or if fees are past due.
                    </p>
                  </div>
				  
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Cancellation & Refund Policy</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">General Services</strong> You're free to stop using our Services at any time. We also reserve the right to suspend or end or refuse to provide Services at any time at our discretion and without notice. For example, we may suspend or terminate Services if you're not complying with these Terms, or use the Services in a manner that would cause us legal liability, disrupt the Services or disrupt others' use of the Services. 
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Appointments</strong> You may re-schedule or cancel an appointment at any time. However, changes to appointments made within 2 business days of the original scheduled appointment time are subject to payment of fees amounting to the full schedule fee for the appointment. Please contact us via <a href="mailto:reception@akkadianhealth.com">reception@akkadianhealth.com</a> to discuss and resolve any matters in relation to cancellation fees or to request a waiver of cancellation fees based on legitimate compassionate grounds.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Non-Attendance</strong> Failure to attend a scheduled appointment without reasonable effort to contact us via <a href="mailto:reception@akkadianhealth.com">reception@akkadianhealth.com</a> within 2 business days of the scheduled appointment shall be treated as a cancellation and are subject to fees amounting to the full schedule fee for the appointment.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">No Refunds after Service</strong> You may cancel a scheduled appointment at any time (under the conditions of this policy) however, subject to your rights at law, you won't be issued or entitled to a refund for any health services received.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Refunds on Pre-Payments</strong> For pre-paid services, if you change your mind and cancel your appointment, you are entitled to a refund under the following schedule:
                    </p>
					<ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Cancellation more than 2 business days before your appointment - 70% refund;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Cancellation within 2 business days of your appointment - 0% i.e., No Refund;
                      </li>					  
					</ul>					  
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Services "AS IS"</h3>
                    <p className="gr-text-9">
                      We strive to provide great Services, but there are certain things that we can't guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, Akkadian AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED "AS IS." WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. Some states don't allow the disclaimers in this paragraph, so they may not apply to you.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Limitation of Liability</h3>
                    <p className="gr-text-9">
                      TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL Akkadian, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS BE LIABLE FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT Akkadian HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (B) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES MORE THAN THE GREATER OF $20 OR THE AMOUNTS PAID BY YOU TO Akkadian FOR THE PAST 12 MONTHS OF THE SERVICES IN QUESTION. Some states don't allow the types of limitations in this paragraph, so they may not apply to you.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Resolving Disputes</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Let's Try To Sort Things Out First</strong> We want to address your concerns without needing a formal legal case. Before making a claim against Akkadian, you agree to try to resolve the dispute informally by contacting <a href="mailto:reception@akkadianhealth.com">reception@akkadianhealth.com</a>. We'll try to resolve the dispute informally by contacting you via email. If a dispute is not resolved within 30 days of submission, you or Akkadian may bring a formal proceeding.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">We Both Agree To Arbitrate</strong> You and Akkadian agree that any dispute we can’t resolve informally will be submitted to arbitration in accordance with, and subject to, The Institute of Arbitrators &amp; Mediators Australia (IAMA) Arbitration Rules. The arbitration will be held in Adelaide, South Australia, or any other location we both agree to in writing.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Controlling Law</h3>
                    <p className="gr-text-9">
                      These Terms will be governed by South Australian law.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Entire Agreement</h3>
                    <p className="gr-text-9">
                      These Terms constitute the entire agreement between you and Akkadian with respect to the subject matter of these Terms, and supersede and replace any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of these Terms. These Terms create no third party beneficiary rights.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Waiver, Severability &amp; Assignment</h3>
                    <p className="gr-text-9">
                      Akkadian’s failure to enforce a provision is not a waiver of its right to do so later. If a provision is found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Terms, and any such attempt will be void. Akkadian may assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the Services.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Modifications</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Terms</strong> We may revise these Terms from time to time, and will always post the most current version on our website. If a revision meaningfully reduces your rights, we will notify you (by, for example, sending a message to the email address associated with your account, posting on a blog or on this page). By continuing to use or access the Services after the revisions come into effect, you agree to be bound by the revised Terms.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Services</strong> Akkadian may update the Services from time to time. If Akkadian changes the Services in a manner that materially changes their functionality, Akkadian will inform Customer via the email address associated with the account.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Acceptable Use Policy</h3>
                    <p className="gr-text-9">
                      By using Akkadian Services, you agree not to misuse these Services. For example, you must not, and must not attempt to do the following:
                    </p>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        probe, scan, or test the vulnerability of any system or network;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        breach or otherwise circumvent any security or authentication measures;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        access, tamper with, or use non-public areas or parts of the Services, or shared areas of the Services you haven't been invited to;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        interfere with or disrupt any user, host, or network, for example by sending a virus, overloading, flooding, spamming, or mail-bombing any part of the Services;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        access or search the Services by any means other than our publicly supported interfaces
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        send unsolicited communications, promotions or advertisements, or spam;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        send altered, deceptive or false source-identifying information, including "spoofing" or "phishing";
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        sell the Services or Services accounts via unauthorized channels;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        use automated or other means to create accounts in bulk or to access the Services other than by using our official interface and/or APIs;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        publish or share materials that are unlawfully pornographic or indecent, or that advocate bigotry, religious, racial or ethnic hatred;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        publish or share information about other users of the Services without their permission;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        violate the law in any way including storing, publishing or sharing material that's fraudulent, defamatory, misleading, or that violates the privacy or infringes the rights of others;
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        impersonate others by providing fraudulent or falsified documents or by sharing misleading personal information such as your name, email address, phone number, payment/credit card details or Medicare details.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        abuse, threaten or otherwise behave unreasonably towards Akkadian staff delivering or administering the Services.
                      </li>
                    </ul>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Thank You!</h3>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsOfUsePage;
